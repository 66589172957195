<template>
  <div class="degreeClassManagemant">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='degreeClassManagemant'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="班级管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="班级标题">
                <el-input
                  v-model="formInline.name"
                  placeholder="班级标题"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="发布状态">
                <el-select v-model="formInline.releaseStatus" placeholder="发布状态" filterable clearable>
                  <el-option label="未发布" value="1"></el-option>
                  <el-option label="已发布" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button
                class="mainBtn"
                @click="onSubmit"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetEvent" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="classEvent('add')" v-if="$isShowModle('tch_foreign_class_add_edit')" plain>添加班级</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
          >
            <el-table-column
              prop="name"
              label="班级标题"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name || '/' }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="releaseStatus"
              label="发布状态"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                <el-switch
                  :disabled="!$isShowModle('tch_foreign_class_publish')"
                  :active-value="2"
                  :inactive-value="1"
                  @change="chageReleaseStatus(scope.row)"
                  v-model="scope.row.releaseStatus">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="课程"
              align="center"
            >
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="selectCourse(scope.row)" v-if="!scope.row.courseName">选择课程</el-button>
                <el-button type="text" size="small" @click="selectCourse(scope.row)" v-else>{{ scope.row.courseName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="课件"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpCourse(scope.row)" v-if="$isShowModle('tch_foreign_class_resource') && scope.row.courseName">{{ scope.row.coursewareCount || '0' }}</el-button>
                <span v-else>{{ scope.row.coursewareCount || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="专项练习"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpSpecial(scope.row)" v-if="$isShowModle('tch_foreign_class_resource') && scope.row.courseName">{{scope.row.specialExercisesCount || '0'}}</el-button>
                <span v-else>{{scope.row.specialExercisesCount || '0'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="真题精编"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpPaper(scope.row)" v-if="$isShowModle('tch_foreign_class_resource') && scope.row.courseName">{{scope.row.realPaperCount || '0'}}</el-button>
                <span v-else>{{scope.row.realPaperCount || '0'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="mockPaperIds"
              label="模拟考试"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpMockPaper(scope.row)" v-if="$isShowModle('tch_foreign_class_resource') && scope.row.courseName">{{scope.row.mockPaperCount || '0'}}</el-button>
                <span v-else>{{scope.row.mockPaperCount || '0'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="学习人数"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="jumpStudyNum(scope.row)" v-if="$isShowModle('tch_foreign_class_stu_query') && scope.row.courseName">{{scope.row.learnCount || '0'}}</el-button>
                <span v-else>{{scope.row.learnCount || '0'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="120"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="classEvent('edit', scope.row)" v-if="$isShowModle('tch_foreign_class_add_edit')">编辑</el-button>
                <el-button type="text" @click="dialogDelDiyEvent(scope.row)" v-if="$isShowModle('tch_foreign_class_del')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>

        <dialogDiy :isShow="isShowClass" :dialogDiyData="dialogClassDiyData"
        @dialogEvent="dialogClassEvent">
          <div slot="dialogContent" class="class_area">
            <el-form :inline="true" :model="classForm" class="demo-form-inline" label-width="100px" :rules="rules">
              <el-form-item label="班级标题" prop="foreignClassName">
                <el-input
                  v-model="classForm.foreignClassName"
                  placeholder="班级标题"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button @click="dialogClassEvent">取消</el-button>
              <el-button type="primary" @click="enterEvent">确认</el-button>
            </div>
          </div>
        </dialogDiy>

        <dialogDiyNew :isShow="isShowAdd" :dialogDiyData="dialogAddDiyData"
        @dialogEvent="isShowAdd = false">
          <div slot="dialogContent" class="course_area">
            <!-- <div class="man-title" v-if="selectData.length != 0">已添加课件：</div>
            <div class="select-area">
              <div class="manage" v-for="(item, index) in selectData" :key="index" @mousemove="showEvent(index, 'over')" @mouseleave="showEvent(index, 'leave')">
                <img class="manClose" v-show="selectData[index].isShow" src="../../assets/img/man-close.png" alt="" @click="byDeleteEvent(index)">
                <span>{{item.coursewareName || '/'}}</span>
              </div>
            </div> -->
            <div class="search_sList">
              <div class="top_opera">
                <el-form
                  :inline="true"
                  :model="courseForm"
                  class="demo-form-inline"
                  label-width="80px"
                >
                  <el-form-item label="课程名称">
                    <el-input
                      v-model="courseForm.name"
                      placeholder="课程名称"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div class="btn_search_area">
                  <el-button
                    class="mainBtn"
                    @click="onCourseSubmit"
                    plain
                    v-preventReClick
                    >搜索</el-button
                  >
                  <el-button type="info" @click="reseCourseEvent" plain>重置</el-button>
                </div>
              </div>
            </div>
            <div class="table_list_new">
              <el-table
                :data="tableCourseData"
                stripe
                border
                style="width: 100%"
                align="center"
                height="400"
              >
                <el-table-column
                  label=" "
                  align="center"
                  width="40"
                >
                  <template slot-scope="scope">
                    <el-checkbox @change="checkCourse(scope.row)" v-model="scope.row.checked"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="课程名称"
                  align="center"
                >
                  <!-- <template slot-scope="scope">
                    <el-tooltip
                      :content="scope.row.courseName"
                      placement="top"
                      effect="light"
                      popper-class="pubToop"
                    >
                      <div class="ellipsis">{{ scope.row.courseName || '/' }}</div>
                    </el-tooltip>
                  </template> -->
                </el-table-column>
              </el-table>
              <Pagination
                @changePage="changeCoursePage"
                :childMsg="childCourseMsg"
              ></Pagination>
            </div>
            <div class="btn_area">
              <el-button @click="isShowAdd = false">取消</el-button>
              <el-button type="primary" @click="enterClassEvent">确认</el-button>
            </div>
          </div>
        </dialogDiyNew>

        <dialogDiy
          :isShow="isShowDel"
          :dialogDiyData="dialogDelDiyData"
          @dialogEvent="isShowDel = false"
        >
          <div slot="dialogContent" class="dia_opera">
            <img class="errIcon" src="../../assets/img/warn.png" alt="" />
            <p>确定要删除该班级吗？</p>
            <div class="btn_area">
              <el-button @click="isShowDel = false">关闭</el-button>
              <el-button class="mainBtn" @click="doEvent">确定</el-button>
            </div>
          </div>
        </dialogDiy>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
import Pagination from '../Pagination'
import publicChildren from '../publicChildren'
import dialogDiy from '../commonModule/dialogDiy'
import dialogDiyNew from '../commonModule/dialogDiyNew'
export default {
  name: 'degreeClassManagemant',
  components: {
    Pagination,
    publicChildren,
    dialogDiy,
    dialogDiyNew
  },
  data() {
    let that = this;
    async function lazyLoad(node, resolve) {
      const { level } = node;
      if(level == '0') return;
      else if(level == 1) {
        that.classOptionForm.type = 1;
        that.classOptionForm.eduCategory = null;
      } else if(level == 2) {
        that.classOptionForm.eduCategory = node.value;
      }
      let nodes = await that.getAidClassificationData();
      nodes.forEach(item => item.leaf = level >= 2)
      setTimeout(() => {
        resolve(nodes);
      }, 600);
    }
    return {
      activeName: 'first',
      value: [],
      formInline: {
        name: null,
        current: 1,
        size: 10,
        releaseStatus: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      value: [],
      isShowClass: false,
      dialogClassDiyData: {
        title: '添加班级',
        sizeStyle: {width: '400px',height: '250px'}
      },
      classOptionFormNew: {
        eduCategory: null,
        type: null
      },
      classForm: {
        foreignClassName: null
      },
      classAidData: [],
      rules: {
        foreignClassName: [
          { required: true, message: "请输入班级标题", trigger: "blur" }
        ]
      },
      type: null,
      isShowDel: false,
      dialogDelDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
      props: {
        lazy: true,
        value: 'assortValue',
        label: 'assortName',
        lazyLoad
      },
      eduFlag: null,
      idArr: [],
      courseForm: {
        name: null,
        page: 1,
        pageSize: 10
      },
      tableCourseData: [],
      isShowAdd: false,
      dialogAddDiyData: {
        title: '选择课程',
        sizeStyle: {width: '760px',height: '760px'}
      },
      childCourseMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      checkedRow: {}
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "degreeClassManagemant") {
          this.getDegreeClassToPage()
        }
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 搜索
    onSubmit() {
      this.formInline.current = 1;
      this.formInline.size = 10;
      this.getDegreeClassToPage();
    },
    // 重置
    resetEvent() {
      this.formInline.name = null
      this.formInline.current = 1
      this.formInline.size = 10
      this.formInline.releaseStatus = null
    },
    // 获取班级表格数据
    getDegreeClassToPage() {
      let obj = this.formInline;
      this.$request.degreeClassToPage(obj).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.current = val.current;
      this.formInline.size = val.size;
      this.getDegreeClassToPage();
    },
    dialogClassEvent() {
      this.isShowClass = !this.isShowClass;
    },
    classEvent(type, row) {
      this.type = type
      this.rowData = row
      this.dialogClassDiyData.title = type == 'add' ? '添加班级' : '编辑班级'
      this.classForm.foreignClassName = type == 'add' ? null : row.name;
      this.dialogClassEvent()
    },
    enterEvent() {
      if(!this.classForm.foreignClassName) {
        this.Warn('班级标题未填');
        return;
      }
      if(this.type == 'add') {
        this.$request.addDegreeClass(this.classForm).then(res => {
          if (res.data.code == 0) {
            this.Success(res.data.data);
            this.classForm.foreignClassName = null;
            this.getDegreeClassToPage();
            this.dialogClassEvent();
          }
        });
      } else {
        let tchForeignClass = {
          courseId: this.rowData.courseId,
          courseName: this.rowData.courseName,
          id: this.rowData.id,
          name: this.classForm.foreignClassName,
          releaseStatus: this.rowData.releaseStatus
        }
        this.$request.editDegreeClass(tchForeignClass).then(res => {
          if (res.data.code == 0) {
            this.Success(res.data.data);
            this.classForm.foreignClassName = null;
            this.getDegreeClassToPage();
            this.dialogClassEvent();
          }
        });
      }
    },
    chageReleaseStatus(row) {
      let obj = {
        id: row.id,
        status: row.releaseStatus
      }
      this.$request.releaseDegreeClass(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
        } else if(res.data.code == 1) {
          // console.log(row.releaseStatus)
          row.releaseStatus = row.releaseStatus == 1 ? 2 : 1
        }
      }).catch(err => {
        row.releaseStatus = row.releaseStatus == 1 ? 2 : 1
      })
    },
    handleChange(val) {
      this.formInline.type = val[0]
      this.formInline.eduCategory = val[1]
      this.formInline.subjectCode = val[2]
    },
    dialogDelDiyEvent(row) {
      this.rowData = row;
      this.isShowDel = !this.isShowDel;
    },
    doEvent() {
      this.$request.deleteDegreeClass({id: this.rowData.id}).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.getDegreeClassToPage();
          this.isShowDel = !this.isShowDel;
        }
      });
    },
    jumpStudyNum(row) {
      this.$router.push({name: 'degreeSudyDetails', query: {
        foreignClassId: row.id
      }})
    },
    jumpCourse(row) {
      this.$router.push({name: 'degreeCourse', query: {
        foreignClassId: row.id
      }})
    },
    jumpSpecial(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'degreeExercises', query: {
        foreignClassId: row.id,
        // ids: row.specialExercisesIds
      }})
    },
    jumpPaper(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'degreeExamination', query: {
        foreignClassId: row.id,
        // ids: row.realPaperIds,
        year: row.year,
        type: 3,
        headTitle: '真题精编详情'
      }})
    },
    jumpMockPaper(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'degreeExamination', query: {
        foreignClassId: row.id,
        // ids: row.mockPaperIds,
        type: 1,
        headTitle: '模拟考试详情'
      }})
    },
    // 选择课程
    selectCourse(row) {
      this.rowData = row
      this.isShowAdd = !this.isShowAdd
      this.getDegreeClassList()
    },
    getDegreeClassList() {
      this.$request.degreeClassList(this.courseForm).then(res => {
        if(res.data.code == 0) {
          let { records, current, size, total } = res.data.data
          records.forEach(item=>item.checked = false)
          this.tableCourseData = records
          this.childCourseMsg = {
            current,
            size,
            total
          }
          this.checkCourse()
        }
      })
    },
    checkCourse(row) {
      if(row) this.checkedRow = row
      this.tableCourseData.forEach(item => {
        item.checked = false
        if(item.id == this.checkedRow.id) item.checked = true
      })
    },
    onCourseSubmit() {
      this.courseForm.page = 1
      this.courseForm.pageSize = 10
      this.checkedRow = {}
      this.getDegreeClassList()
    },
    reseCourseEvent() {
      this.courseForm.name = null
      this.courseForm.page = 1
      this.courseForm.pageSize = 10
    },
    changeCoursePage(val) {
      this.childCourseMsg = val;
      this.courseForm.page = val.current;
      this.courseForm.pageSize = val.size;
      this.getDegreeClassList();
    },
    // 选择课程
    enterClassEvent() {
      if(!this.checkedRow.id) {
        this.Warn('请选择课程');
        return;
      }
      let obj = {
        courseId: this.checkedRow.id,
        courseName: this.checkedRow.name,
        foreignClassId: this.rowData.id
      }
      this.$request.setUpDegreeClass(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg)
          this.getDegreeClassToPage()
          this.checkedRow = {}
          this.isShowAdd = !this.isShowAdd
          this.clearAddEvent()
        }
      })
    },
    clearAddEvent() {
      this.courseForm.name = null
      this.courseForm.page = 1
      this.courseForm.pageSize = 10
    }
  },
}
</script>

<style lang='less'>
  .degreeClassManagemant {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .class_area {
      height: calc(~'100% - 70px');
      box-sizing: border-box;
      padding: 0px 20px;
      position: relative;
      .demo-form-inline {
        padding-top: 20px;
      }
      .btn_area {
        text-align: right;
        padding: 20px 0px;
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .course_area {
      padding: 30px;
      box-sizing: border-box;
      .btn_area {
        text-align: right;
      }
    }
  }
</style>